.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the dialog is above other content */
}

.dialog {
  width: 1244px; /* Width of the dialog */
  height: 631px; /* Height of the dialog */
  background-color: #F7F8FB; /* White background */
  border-radius: 10px;
  position: relative; /* For positioning the close button */
  padding: 20px; /* Padding inside the dialog */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the heading horizontally */
}

.dialog-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h2 {
  margin: 0;
  font-size: 24px;
  text-align: center;
  flex-grow: 1;
}

.close-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.close-btn:hover {
  color: #f00; /* Red on hover */
}

.dialog-separator {
  width: 1283.2px;
  height: 2px;
  background-color: #272635; /* Color of the separator line */
  margin: 35px 0; /* Adjust spacing */
}

.dialog-body {
  width: 100%;
  display: flex;
  padding-top: 45px;
}

.dialog-text {
  width: 50%;
  padding: 0 20px;
}

.dialog-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-image img {
  max-width: 471px; /* Ensure the image fits within its container */
  max-height: 353px; /* Ensure the image fits within its container */
  border-radius: 10px;
}