.about{
    display: flex;
   
}
.about{
    font-family: "Lora", Georgia, serif;
    text-align: left;
    margin-right: 5%;
    margin-left: 5%;
}
.abouth1 {
    font-size: 4.20em;
    margin: 0.0em 0 0.4em 0;
}
.abouth3{
    text-align: left;
}
.abouthr {
    /* padding: 0.6em 0 1em 0; */
    outline: 0;
    border-width: 0 0 1px 0;
    border-style: dashed;
    border-color: rgb(15, 1, 1);
}
.about-heading{
    text-align: center;
    padding: 30px;
    border-top: 1px solid #272635 ;
    border-bottom: 1px solid #272635 ;
}
.about-body-1{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.about-body-11{
    flex: 50%;
}
.about-body-2{
    padding-top: 30px;
    background-color: #ffd1b9;
    align-items: center;
}
.about-body-2 > h1{
    text-align: center;
}
.about-body-2 > p{
    text-align: center;
    width: 50%;
    margin-left: 25%;
}
.about-body-31{
    display: flex;
}
.about-body-31-img{
    margin-top: 5%;
    margin-left: 10%;
}
.about-body-31 > h1{
    width: 50%;
    margin-left: 5%;
}
.about-body-3 > hr{
    outline: 0;
    border-width: 0 0 1px 0;
    border-style: dashed;
    border-color: rgb(231, 227, 227);
}
.about-body-3{
    
    background-color: #272635;
    color: white;
    padding: 20px;
}

.about-body-32{
   padding-top: 20px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
}
.btn-write-on-medium {
    margin-top: 20px;
    padding: 8px 32px 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #34a245;
    border-radius: 20px;
    font-size: 20px;
    color: #34a245;
    background-color: #272635;
  }
.div-write-on-medium :hover{
    /* background-color: #f5fdf6; */
    color: #f8fff9;
    cursor: pointer;
    border-color: #f8fff9;
    

}
.btn-get-started-medium {
    margin-top: 20px;
    padding: 8px 32px 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #34a245;
    border-radius: 20px;
    font-size: 20px;
    color: #34a245;
    background-color: #272635;
  }
.div-get-started-medium :hover{
    /* background-color: #f5fdf6; */
    color: #f8fff9;
    cursor: pointer;
}
.about-body-4{
    background-color: #34a245;
    display: flex;
}
.about-body-41{
    width: 50%;
    margin-left: 2%;
    margin-right: 2%;
    border-right: 1px solid #000;
    padding: 10%;
}
.about-body-42{
    margin-top: 5%;
}
.about-footer {
    text-align: center;
    align-items: center;
    margin: 30px;
}
.about-footer > div {
    display: flex;
    margin-left: 45%;
}
.about-footer > div > p{
    padding-right: 20px;
    text-align: center;
    text-decoration: underline;
    color: rgb(126, 121, 114);
    
}
.avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.user-list{
    display: flex;
    list-style: none;
    flex-wrap: wrap;
   
    
}  
.user-list > li{
    display: flex;
    padding: 10px;
    margin-right: 3%;
    width: 22%;
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
}
.user-list >li > p{
    margin-top: 5%;
    margin-left: 15px;
    font-size: larger;
    font-weight: bold;
}
/* .user-list >ul >a>li > img{
    margin-left: 50px;
} */