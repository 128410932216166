.BodyCategoryList {
  position: relative;
  width: 100%;
  height: 100vh; /* Cover the full height of the viewport */
  overflow: hidden;
  z-index: 1; /* Ensure it is below the header */
}

.background-categoryList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://myflowimages.s3.us-east-2.amazonaws.com/frontend/backgroundImage.jpeg");
  background-size: cover;
  background-position: center;
  z-index: 0;
}

.separator-line {
  position: absolute;
  top: 716px;
  left: 50%;
  width: 90%;
  /* height: 1px;
    background-color: black; Adjust color as needed */
  z-index: 2; /* Ensure it's above the background but below other content */
  border: 2px solid #272635;
  transform: translateX(-50%);
}

.card-container-cat {
  position: relative;
  /* z-index: 3; Ensure it's above the separator line */
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.card-content-categoryList {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
  margin-right: 200px;
  margin-left: 200px;
}

.card-categoryList {
  width: 400px;
  height: 400px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.image-container-categoryList {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-categoryList img {
  width: 100%;
  height: auto;
  display: block;
}

.card-content {
  padding: 10px;
}

.card-content h2 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-content p {
  font-size: 14px;
}

.text-overlay {
  position: absolute;
  top: 489px;
  left: 125px;
  z-index: 2;
  color: #272635;
  font-size: 36px;
  font-weight: 700;
}

.filter-btn {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  width: "639px";
  height: "76px";
  font-size: "36px";
  margin-left: 42%;
}

.text-below-line {
  position: absolute;
  top: 778px; /* Adjust as needed for spacing below the line */
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  text-align: center;
  color: #272635; /* Adjust text color as needed */
  z-index: 2; /* Ensure it's above the background but below other content */
  font-size: 20px;
  font-weight: 400;
}

.filter-dialog-category {
  width: 182px;
  height: 165px;
  border-radius: 10px;
  background-color: #a6a6a8;
  padding: 20px;
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 42%;
}

@media (max-width: 768px) {
  .separator-line {
    display: none;
  }

  .BodyCategoryList {
    height: 22%;
  }

  .text-overlay {
    position: absolute;
    top: 50%;
    left: 5%;
    font-size: 14px;
  }

  .card-container-cat {
    padding: 0;
  }

  .card-content-categoryList {
    flex-direction: column; /* Stack cards vertically */
    gap: 20px;
    margin: 10px;
  }

  .card-categoryList {
    width: 90%; /* Full width on mobile */
    margin: 0 auto; /* Center cards */
  }

  .text-below-line {
    top: 75%;
    font-size: 14px;
  }

  .filter-btn {
    width: 110px !important;
    height: auto !important;
    font-size: 8px; /* Smaller font size */
    padding: 10px;
    font-weight: 600;
    margin: 10px auto;
  }

  .filter-dialog-category {
    width: 90%;
    height: auto; /* Dynamic height */
  }

  .pagination {
    gap: 5px;
  }

  .pagination button {
    width: 100px; /* Fixed button width */
    height: 40px;
  }
}
