.write {
  margin: 0 auto;
  max-width: 1300px;
}

.write-title {
  padding-bottom: 50px;
  margin-top: -5%;
  font-size: 28px;
  font-weight: 700;
}

.writeImg {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 20px;
}

.writeForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.writeFormGroup .info-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #555; /* or any color you prefer */
}

.writeFormGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.writeFormGroup > label {
  font-size: 25px;
  font-weight: 500;
  min-width: 210px;
}

.writeFormGroup > input {
  max-width: 79%;
}

.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgb(121, 118, 118);
  cursor: pointer;
}

.writeInput {
  font-size: 24px;
  border: none;
  padding: 10px;
  flex-grow: 1;
  border-radius: 5px;
  background-color: #c9cdd3;
}

.writeInput:focus {
  outline: none;
}

.writeText {
  font-size: 18px;
  width: 90%;
  background-color: #c9cdd3;
  border-radius: 10px;
}

.writeText .ql-container {
  border: none !important;
  box-shadow: none !important;
}

.writeText .ql-editor {
  border: none !important;
  box-shadow: none !important;
}

.writeText .ql-toolbar {
  border: none;
  box-shadow: none;
}

.writeSubmit {
  color: #272635;
  background-color: #b1e5f2;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  margin-right: 10px;
  height: 32px;
  width: 101px;
}

.write-buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.writeFormGroup-buttons {
  display: flex;
  justify-content: flex-end; /* Aligns all items to the right */
  align-items: center; /* Centers items vertically */
  gap: 10px; /* Adds space between buttons */
  padding: 10px; /* Optional padding */
}

.writeSubmitDraft {
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  height: 32px;
  width: 105px;
}

.rtxEditor {
  background-color: #34a245;
}

.Write {
  font-family: sans-serif;
  text-align: left;
  padding: 20px;
}

.optionClass {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.select {
  width: 81%;

  align-items: center;
}

/* .optionClass .select {
  background-color: #C9CDD3;
} */

.categories,
.tags {
  display: flex;
  align-items: center;
  gap: 10px;
}

.categories > label,
.tags > label {
  font-size: 25px;
  font-weight: 500;
  min-width: 210px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 25px;
  padding: 15px;
  background-color: #f7f8fb;
  color: #272635;
  transition: width 0.3s ease;
  overflow: auto;
  border-left: 1px solid #272635;
}

.sidebar.open {
  width: 950px;
  z-index: 1003;
}

.sidebar.closed {
  width: 25px; /* Width when the sidebar is closed */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  max-height: 100vh; /* Ensure the sidebar doesn't overflow the viewport */
  overflow-y: auto; /* Add scrolling if the content is too much */
  padding: 10px;
  transition: opacity 0.3s ease;
  opacity: 1;
}

.sidebar.closed .sidebar-content {
  opacity: 0; /* Hide the content when the sidebar is closed */
  pointer-events: none; /* Disable interaction when the sidebar is closed */
}

.sidebar-toggle {
  position: absolute;
  top: 50%;
  left: 10px;
  background-color: #555;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
}

.dimmed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(39, 38, 53, 0.7); /* #272635 with 70% opacity */
  z-index: 900; /* Ensure it’s below the sidebar but above the content */
  display: none; /* Hidden by default */
}

.dimmed-background.visible {
  display: block;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  margin-left: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #555; /* Adjust color as needed */
}

.tooltip {
  visibility: visible;
  background-color: #e8e9f3;
  color: #272635;
  text-align: center;
  padding: 10px;
  border-radius: 15px;
  position: absolute;
  z-index: 1;
  /* left: 100%; Position the tooltip on the right of the icon */
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 8px;
}

.tooltip-container .tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -10px; /* Position the arrow to point to the icon */
  transform: translateY(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent #e8e9f3 transparent transparent; /* Creates a right-pointing arrow */
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.featured-images {
  display: flex;
  align-items: center;
  gap: 10px;
}

.featured-images > label {
  font-size: 25px;
  font-weight: 500;
  min-width: 210px;
}
.featured-images .featured-images-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #c9cdd3;
}

.featured-images .image-featured-images {
  cursor: pointer;
}

.featured-images .image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-grow: 1;
}

.featured-images .image-wrapper {
  position: relative;
  display: inline-block;
}

.featured-images .image-wrapper img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.featured-images .upload-icon {
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
  margin-left: auto;
}

.remove-image-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ff0000; /* Red color for the "x" button */
  padding: 0;
}

.remove-image-btn:hover {
  color: #ff4d4d; /* Hover effect */
}

.inside-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center items horizontally */
  border: 1px solid #ccc; /* Optional: to visualize the container */
  padding: 5px; /* Optional: add some padding around items */
}

.writeText .ql-toolbar {
  border: none;
  border-radius: 10px;
}

.writeText .ql-editor {
  border: none; /* Ensure no border around the editor area */
  height: 400px;
  border-radius: 10px;
}
