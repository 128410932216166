.bodySubscribe {
  background-color: #f5f5f5; /* Ghost white background */
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.header-subscribe {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 150px;
}

.logo-subscribe {
  width: 233px;
  height: 233px;
  /* opacity: 0.35; */
  position: absolute;
  top: 0%;
  left:  0%;
  mix-blend-mode: multiply;
}

.logo img {
  width: 188px; /* Adjust as needed */
  height: 148px;
  mix-blend-mode: multiply;
  top: 5px;
  left: 15x;
}

.header-right {
  position: absolute;
  top: 55px;
  right: 85px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.subscribe-btn {
  width: 117px;
  height: 46px;
  font-size: 25px;
  font-weight: 500;
  background-color: #c9cdd3;
  color: #272635;
  border: none;
  border-radius: 10px;
  margin-right: 20px;
  /* Add shadow effect */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  /* Center text */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.separator-subscribe {
  border-right: 1px solid #272635;
  height: 60px;
  margin-right: 20px;
}

.search-icon-subscribe img {
  width: 40px;
  height: 39px;
  margin-right: 20px;
}

.hamburger-icon {
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}

button:hover {
  opacity: 0.8;
}
.socialButton {
  background-color: #ffffff;
  color: white;
  padding: 30px; /* Maintain original button size */
  margin: 8px;
  /* border: black; */
  cursor: pointer;
  border-radius: 50%; /* Make buttons circular */

  border: 1px solid #272635;
}

.google {
  background-color: #ffffff;
}

.facebook {
  background-color: #ffffff;
}

.apple {
  background-color: #ffffff;
}

.socialButton img {
  width: 40px;
  height: 40px;
}

.button-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

main {
  padding: 1rem; /* Padding for main content area */
}

.center-text {
  text-align: center;
  margin-bottom: 0rem; /* Spacing between text elements */
}

.center-text h1 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem; /* Reduce margin below heading */
}

.card-container-subscribe {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to next line if needed */
  justify-content: space-evenly; /* Space cards evenly */
  padding-bottom: 140px;
  /* Spacing between cards */
}

.card-subscribe {
  width: 531px; /* Adjust width for two cards with spacing */
  height: 390px;
  background-color: #a6a6a8;
  border-radius: 10px; /* Rounded corners */
  padding: 1rem;
}

.card-subscribe h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.card-subscribe button {
  background-color: #b1e5f2; /* Dark gray for button background */
  color: #272635; /* White text for button */
  
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
  margin-left: 114px;
  margin-top: 45px;
}

/* ---------------------------------------- */

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #b1e5f2;
  justify-content: center;
  align-items: center;
  z-index: 99;
  transition: opacity 0.3s ease-in-out;
  padding-top: 300px;
}

.mobile-menu.hidden {
  opacity: 0;
  pointer-events: none;
}

.mobile-menu-content {
  border-radius: 10px;
  text-align: center;
}

.menu-items {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 35px; /* Adjust font size as needed */
}

/* Background on menu item, clear bottom border (optional) */
.menu-item {
  margin-bottom: 1rem;
  position: relative; /* Enable positioning of child element */
  overflow: hidden; /* Hide overflowing content */
  background-color: transparent; /* Set background for menu item */
  border-bottom: none; /* Clear potential default border (optional) */
}

.menu-item a {
  color: #272635;
  text-decoration: none;
  font-size: inherit; /* Inherit font size from .menu-items */
  transition: color 0.3s ease-in-out;
  width: 100%; /* Stretch link to full width */
  display: block; /* Make link take full height of menu item */
  padding: 1rem 0; /* Add some padding for visual separation */
  position: relative; /* Position for layering */
}

.menu-item a:hover {
  color: #f7f8fb; /* Hover text color */
  background-color: #272635;
}

/* Animated hover effect remains the same */
.menu-item a:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start from left off-screen */
  width: 100%;
  height: 100%;
  background-color: #272635; /* Hover background color */
  transition: left 0.3s ease-in-out;
}

/* Close icon styles */
.close-icon-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

/* -------------------- */

.social-icons {
  position: absolute;
  bottom: 1rem; /* Adjust distance from bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally (alternative) */
  display: flex; /* Arrange icons horizontally */
  justify-content: center; /* Center icons horizontally */
  /* Add spacing between icons */
  gap: 50px; /* Adjust spacing between icons as needed */
}