.not-subscribed {
  margin-left: 25px;
}

.not-subscribed-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(531px, 1fr));
  gap: 80px; /* Adjust the gap between cards as needed */
  justify-content: center;
  align-items: center;
  padding: 50px; /* Optional: Adds padding around the grid container */
}

.not-subscribed-card {
  width: 531px;
  height: 298px;
  background-color: #a6a6a8; /* Example background color, adjust as needed */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px; /* Optional: Adds padding inside the card */
  box-sizing: border-box;
}

.horizontal_line {
  width: 102.5%;
  height: 10px;
  border-top: 1px solid #272635;
  line-height: 80%;
  margin-top: 20px;
  margin-left: -10px;
}
