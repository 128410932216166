.author-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 0;
}

.author-image img {
  width: 459px;
  height: 643px;
  object-fit: cover;
  border-radius: 10px;
}

.author-bio {
  margin-left: 20px;
  max-width: 600px;
  text-align: center;
}

.author-bio h2 {
  margin-top: 0;
  font-size: 36px;
  font-weight: 700;
  padding-bottom: 20px;
}

.author-bio p {
  white-space: pre-line;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
}

.posted-article {
  text-align: center;
  margin-top: 20px;
}

.posted-article-text {
  font-size: "36px";
  font-weight: "700";
  padding-top: "20px";
  margin-bottom: "40px";
}

.divider {
  border: 1px solid #272635;
  margin: 20px auto; /* Centers the hr element */
  width: 1250px;
}

.single-author-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .author-section {
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
    margin-bottom: 8.5%;
  }

  .author-image img {
    width: 358px;
    height: 188px;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .author-bio {
    margin-left: 0;
    text-align: center;
    padding: 0 15px;
    max-width: 75%;
    font-weight: 400;
  }

  .author-bio h2 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .author-bio p {
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
  }

  .divider {
    border: 1px solid #272635;
    margin: 20px auto; /* Centers the hr element */
    width: 1250px;
    margin-top: -5%;
  }
}