
    
    @font-face {
      font-family: 'Roboto-Medium';
      src: url('../../../public/web fonts/roboto_medium_macroman/Roboto-Medium-webfont.woff') format('woff');
      font-weight: normal;
      font-style: normal;
    }

    ::selection {
      background-color: #b1e5f2;
      color: #272635; /* Optional: Adjust text color for better readability */
    }

    body{
      font-family: 'Roboto-Medium';
    }
        
    .bodyHome {
      margin: 0;
      padding: 0;
      height: 100%;
      font-family: 'Roboto-Medium';
      font-weight: 300;
      font-style: normal;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("https://myflowimages.s3.us-east-2.amazonaws.com/frontend/Homepage.jpg");
      background-size: cover;
      background-position: center;
      z-index: -1; /* Ensure the background is behind other content */
    }

    .container-home {
      display: flex;
      flex-direction: column;
      height: 80%;
    }

    .headerHome {
      display: flex;
      justify-content: space-between;
      /* position: relative; */
    }

    .logoHome img {
      width: 233px; /* Adjust as needed */
      height: 233px;
      mix-blend-mode: multiply;
   
    }

    .header-right-Home {
      position: absolute;
      top: 55px;
      right: 35px;
      display: flex;
      align-items: center;
    }

    .sign-in {
      margin-right: 20px;
    }

    .subscribe-btn-Home {
      background-color: #b1e5f2;
      color: #272635;
      border: none;
      border-radius: 5px;
      margin-right: 20px;
      /* Add shadow effect */
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      font-family: 'Roboto-Light';
      cursor: pointer;
      height: 44px;
      width: 151px;
      font-size: 20px;
    }

    .separator-home {
      border-right: 1px solid #272635;
      height: 55px;
      margin-right: 20px;
    }

    .search-icon img {
      width: 40px;
      height: 39px;
    }

    .hamburger-icon {
      font-size: 20px;
      cursor: pointer;
      margin-right: 20px;
    }

    .menuIcon {
      font-size: 30px;
    }

    .closeIcon {
      font-size: "30px";
      color: "#272635";
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 185px;
    }

    .centered-text {
      text-align: center;
      margin-bottom: 20px;
      color: white;
      text-decoration: none;
    }

    .intro-text{
      font-size: 64px;
      font-weight: 500;
    }

    .centered-btn {
      background-color: #272635;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 10px;
      cursor: pointer;
      width: 393px;
      height: 90px;
      margin-top: 10px;
      font-size: 45px;
    }

    .containertwo {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center vertically */
      align-items: center; /* Center horizontally */
      padding-top: 22%;
      padding-bottom: 70px;
    }

    .section {
      display: flex;
      align-items: center;
      margin: 50px 0;
    }
    
    .image {
      padding-left: 35px;
      position: relative; /* Added to make the container relative for the caption positioning */
    }
    
    .text {
      padding-left: 110px;
    }
    
    .section.reverse {
      flex-direction: row-reverse;
    }
    
    .section img {
      width: 486px;
      height: 397px;
      border-radius: 10px;
      object-fit: cover;
    }

    .carousel-container {
      width: 486px;
      height: 397px;
      position: relative;
    }
    
    .carousel .slide {
      display: flex;
    }
    
    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensures the image covers the entire container */
      border-radius: 10px;
    }

    .carousel.carousel-slider .control-arrow {
      top: 182px;
      color: #FFFCFC;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px;
      border: black;
  }
    
    .caption {
      position: absolute;
      bottom: 50px;
      width: 100%;
      background-color: rgba(39, 38, 53, 0.55);
      color: white;
      text-align: center;
      padding: 10px;
      box-sizing: border-box;
      font-size: 25px;
      font-weight: 500;
    }
    
  
    
    .control-prev {
      left: 10px;
    }
    
    .control-next {
      right: 10px;
    }
    
    /* Hide the default white spaces around the arrows */
    .carousel .control-next.control-arrow:before, 
    .carousel .control-prev.control-arrow:before {
      display: none;
    }
    
    .text-button {
      text-align: center;
    }
    
    .text-button h1 {
      margin-bottom: 10px;
    }
    
    .text-button button {
      width: 270px;
      height: 52px;
      border: none;
      background-color: #272635;
      color: #b1e5f2;
      border-radius: 10px;
      margin-top: 18px;
      font-size: 25px;
      font-weight: 400;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    /* ------------------------- */

    .subscribe-section {
      width: 100%;
      height: 402px;
      background-color: #f0eaea; /* Set the background color as needed */
      padding: 50px 50px; /* Add padding if needed */
      display: flex; /* Use flexbox to center content */
      flex-direction: column; /* Arrange content vertically */
      justify-content: center; /* Center content vertically */
      align-items: center; /* Center content horizontally */
      margin: 0 auto; /* Center the section horizontally within its container */
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }

    .subscribe-section h1 {
      margin-bottom: 10px; /* Adds space between the title and input field */
    }
/* 
    .subscribe-section input[type="email"] {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-right: 0px;
      width: 350px;
    } */

    .subscribe-section .buttonHome {
      background-color: #272635;
      color: #fffcfc;
      padding: 10px 40px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    /* --------------------------------------- */

    /* Mobile menu styles */
    .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #b1e5f2;
      justify-content: center;
      align-items: center;
      z-index: 99;
      transition: opacity 0.3s ease-in-out;
      padding-top: 350px;
    }

    .mobile-menu.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .mobile-menu-content {
      border-radius: 10px;
      text-align: center;
    }

    .menu-items {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 35px; /* Adjust font size as needed */
    }

    /* Background on menu item, clear bottom border (optional) */
    .menu-item {
      margin-bottom: 1rem;
      position: relative; /* Enable positioning of child element */
      overflow: hidden; /* Hide overflowing content */
      background-color: transparent; /* Set background for menu item */
      border-bottom: none; /* Clear potential default border (optional) */
    }

    .menu-item a {
      color: #272635;
      text-decoration: none;
      font-size: inherit; /* Inherit font size from .menu-items */
      transition: color 0.3s ease-in-out;
      width: 100%; /* Stretch link to full width */
      display: block; /* Make link take full height of menu item */
      padding: 1rem 0; /* Add some padding for visual separation */
      position: relative; /* Position for layering */
    }

    .menu-item a:hover {
      color: #f7f8fb; /* Hover text color */
      background-color: #272635;
    }

    /* Animated hover effect remains the same */
    .menu-item a:hover::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%; /* Start from left off-screen */
      width: 100%;
      height: 100%;
      background-color: #272635; /* Hover background color */
      transition: left 0.3s ease-in-out;
    }

    /* Close icon styles */
    .close-icon-container {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    /* -------------------- */

    .social-icons {
      position: absolute;
      bottom: 1rem; /* Adjust distance from bottom */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%); /* Center horizontally (alternative) */
      display: flex; /* Arrange icons horizontally */
      justify-content: center; /* Center icons horizontally */
      /* Add spacing between icons */
      gap: 50px; /* Adjust spacing between icons as needed */
    }

    .signup-email{
      height: 75px;
      width: 519px;
      border-radius: 10px;
      margin-right: 10px;
      font-size: 36px;
      padding-left: 15px;
      border: 1px solid rgb(220,214,214);
      outline: none;
    }

    /* @media (max-width: 1200px) {
     .containertwo{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
      padding-top: 22%;
      padding-bottom: 70px;
     }

     .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
    }
    } */

    @media (max-width: 767.98px) and (max-height: 575.98px) {
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 00px;
      }
      .containertwo{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding-top: 15%;
        padding-bottom: 70px;
       }
       .intro-text{
        font-size: 30px;
        font-weight: 500;
       }

       .centered-btn {
        background-color: #272635;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        width: 270px;
        height: 60px;
        margin-top: 10px;
        font-size: 30px;
      }

      .signup-email{
        height: 75px;
        width: 415px;
        border-radius: 10px;
        margin-right: 10px;
        font-size: 30px;
        padding-left: 15px;
        border: 1px solid rgb(220,214,214);
        outline: none;
      }
    }

    @media (min-width: 768px) and (max-width: 991.98px) and (min-height: 576px) and (max-height: 767.98px) {

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 90px;
      }

      .intro-text{
        font-size: 45px;
        font-weight: 500;
       }

       .centered-btn {
        background-color: #272635;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        width: 300px;
        height: 70px;
        margin-top: 10px;
        font-size: 35px;
      }

      .containertwo{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding-top: 28%;
        padding-bottom: 70px;
       }

       /* Hamburger Menuuuuuuuuuuuuuuu */
       
    }

    @media (min-width: 991.98px) and (max-width: 1200px) and (min-height: 767.98px) and (max-height: 991.98px){ 
      .containertwo{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding-top: 28%;
        padding-bottom: 70px;
       }
    }
  
    @media (min-width: 1200px) and (max-width: 2500px){
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 160px;
      }
      .containertwo{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        padding-top: 20%;
        padding-bottom: 70px;
       }
    }