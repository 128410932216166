.header-title-categories {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
  margin-top: -5%;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
  gap: 40px; /* Add a gap between the cards */
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 20px;
}

.card-categories {
  width: 396px;
  height: 348px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-evenly;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.card-categories img {
  width: 100%;
  height: auto;
  display: block;
}

.caption-categories {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #b1e5f2; /* Semi-transparent background */
  border: 1px solid #272635;
  border-radius: 0 0 10px 10px;
  color: #272635; /* Text color */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  font-size: 25px;
  font-weight: 500;
}

.card-content {
  padding: 10px;
}

.card-content h2 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.card-content p {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .header-title-categories {
    font-size: 14px;
    margin-top: -2%;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    gap: 10px;
  }

  .card-categories {
    width: 45%;
    height: 123px;
    border: none;
  }

  .image-container {
    width: 100%;
    height: 100%;
  }

  .caption-categories {
    font-size: 10px;
    padding: 8px;
  }
  .card-content {
    display: none;
  }
}
