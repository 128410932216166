.page-container {
  display: flex;
  height: 100vh;
}

.logo-register {
  width: 350px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translateY(-95%);
  mix-blend-mode: multiply;
}

.left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b1e5f2;
  position: relative;
  border-right: 1px solid #272635;
}

.right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-register {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.form-register h1{
  font-size: 36px;
  font-weight: 700;
  padding-right: 204px;
  padding-bottom:20px;
}

.form-register input {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.RegisterBtn {
  background-color: #b1e5f2;
  color: #272635;
  padding: 15px 20px; 
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 289px;
  border-radius: 10px;
  font-size: 28px;
  /* font-weight: 700; */
  font-family: 'Roboto-Light';
}

.RegisterBtn:hover {
  opacity: 0.8;
}

.separator-register {
  display: flex;
  align-items: center;
  margin: 10px 10px; /* Adjust spacing between buttons and separator */
  margin-top: 40px;
}

.separator-register hr {
  width: 241px; /* Adjust width of the lines */
  height: 1px; /* Adjust height of the lines */
  border: none; /* Remove default border style */
  background-color: #272635; /* Color of the lines */
  margin: 0 15px; /* Spacing between lines and "OR" text */
}

.separator-register span {
  margin: 0 auto; /* Center "OR" text horizontally */
  font-size: 36px;
  font-weight: 700;
}

.socialButton {
  background-color: #ffffff;
  color: white;
  padding: 30px; /* Maintain original button size */
  margin: 8px;
  /* border: black; */
  cursor: pointer;
  border-radius: 50%; /* Make buttons circular */

  border: 1px solid #272635;
}

.google {
  background-color: #ffffff;
}

.facebook {
  background-color: #ffffff;
}

.apple {
  background-color: #ffffff;
}

.socialButton img {
  width: 40px;
  height: 40px;
}

.button-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-container {
  position: relative; /* Add position relative for absolute positioning of elements */
}

.picture {
  width: 100%; /* Make the picture fill the container width */
  height: 35%; /* Maintain aspect ratio */
  margin-top: auto;
  border-top: 1px solid #272635;
}

/* ----------------------------------------------------------- */

/* Container for each input */
.input-container-register {
  display: flex; /* Use flexbox */
  flex-direction: column; /* Align items vertically */
  margin-bottom: 10px; /* Add some space between input containers */
  font-size: 15px;
  font-weight: bold;
}

/* Label styling */
.input-container-register label {
  align-self: flex-start; /* Do not allow labels to grow or shrink */
  margin-right: 10px; /* Add some space between label and input */
  font-size: 25px;
  font-weight: 500;
}

/* Input styling */
.input-container-register input {
  width: 100%; /* Allow input to grow to fill remaining space */
  background-color: #c9cdd3;
  border-radius: 10px;
  font-size: 16px;
  height: 48px;
}

@media (max-width: 1200px) {
  .logo-register {
    width: 300px;
    height: 300px;
    left: 20%;
  }
  
  .separator-register hr {
    width: 180px;
  }

  .separator-register span {
    font-size: 30px;
  }

  .form-register {
    text-align: center;
    max-width: 400px;
    margin-top: 100px;
    
  }

  .form-register h1{
    font-size: 30px;
    font-weight: 700;
    padding-right: 233px;
    padding-bottom:20px;
  }

  .input-container-register label {
    align-self: flex-start; /* Do not allow labels to grow or shrink */
    margin-right: 10px; /* Add some space between label and input */
    font-size: 20px;
    font-weight: 500;
  }

  .input-container-register {
    display: flex; /* Use flexbox */
    flex-direction: column; /* Align items vertically */
    margin-bottom: 10px; /* Add some space between input containers */
    font-size: 12px;
    font-weight: bold;
  }

  .RegisterBtn {
    background-color: #b1e5f2;
    color: #272635;
    padding: 10px 15px; 
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 289px;
    border-radius: 10px;
    font-size: 28px;
    /* font-weight: 700; */
    font-family: 'Roboto-Light';
  }
}

@media (max-height:850px) {
  .form-register {
    text-align: center;
    max-width: 400px;
    margin-top: 105px;
    
  }
}
@media only screen and (max-width: 480px) {
  .left-container{
    display: none;
  }

  .form-register h1{
    font-size: 14px;
    font-weight: 700;
    margin-left: 80px;
  }
  .input-container-register label{
    font-size: 12px;
    font-weight: 500;
    width: 240px;
    text-align: left;
    margin-left: 78px;
  }

  .form-register {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers items horizontally */
    justify-content: center; /* Centers items vertically */
    height: 100vh; /* Makes the form take the full height of the viewport */
    margin: 0 auto; /* Centers horizontally if not using flexbox */
    box-sizing: border-box; /* Ensures padding is included in dimensions */
  }
  .right-container{
    align-items: center;
    justify-content: center;
  }

  .input-container-register {
    display: flex;
    flex-direction: column; /* Stack inputs vertically */
    align-items: center; /* Align inputs horizontally */
    width: 100%; /* Optional: Adjust to ensure it doesn't shrink */
  }
  
  .input-container-register input {
    width: 240px; /* Adjust width to your preference */
    height: 32px;
  }
  .separator-register hr {
    width: 90px;
  }
  .remember-me{
    margin-left: 80px;
    margin-right: 75px;
  }
  .RegisterBtn {
    background-color: #b1e5f2;
    color: #272635;
    padding: 10px 15px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 115px;
    border-radius: 10px;
    font-size: 12px;
    /* font-weight: 700; */
    font-family: 'Roboto-Light';
  }
  .form-register p{
    font-size: 12px;
  }
  .separator-register span{
    font-size: 12px;
  }
}