.header-container-articles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 160px;
  padding-left: 100px;
  margin-top: -5%;
}

.header-title-articles {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  flex-grow: 1;
  text-align: center;
  margin-left: 200px;
}

.filter-btn {
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  font-size: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 197px;
  height: 49px;
  border-radius: 10px;
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  padding: 0; /* Ensure there is no padding affecting the size */
  margin: 0; /* Ensure there is no margin affecting the size */
  display: "flex"
}
.filter-dialog {
  width: 175px;
  height: 165px;
  border-radius: 10px;
  background-color: #A6A6A8;
  padding: 11px;
  position: absolute;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-dialog h2 {
  margin-top: 0;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkmark {
  width: 19px;
  height: 19px;
  background-color: #C9CDD3;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.custom-checkbox input:checked + .checkmark::after {
  content: "✔";
  color: #272635;
  position: absolute;
  top: 0;
  left: 4px;
  font-size: 14px;
}

.save-btn {
  margin-top: 20px;
  width: 101px;
  height: 32px;
  background-color: #272635;
  border-radius: 10px;
  border: none;
  color: #B1E5F2;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  margin-left: 65px;
}

.home {
  display: flex;
}

.sidebar-home {
  flex: 4;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.sidebarItem-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebarTitle-home {
  margin: 10px;
  padding: 10px;
  width: 80%;
  background-color: #dddbdb;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  color: rgb(22, 22, 22);
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.sidebarItem-home > img {
  margin-top: 15px;
}

.sidebarItem-home > p {
  padding: 30px;
}

.sidebarList-home {
  list-style: none;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.sidebarListItem-home {
  display: inline-block;
  width: 80%;
  margin: 10px;
  margin-right: 80px;
  cursor: pointer;
  padding: 5px;
  border: solid 1px #ccc;
  text-align: center;
  font-family: "Varela Round", sans-serif;
}

.sidebarSocial-home {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarIcon-home {
  font-size: 35px;
  margin-left: 10px;
  cursor: pointer;
  color: #272635;
  padding: 15px;
}

.sidebarIntro-home {
  align-items: center;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  margin-bottom: 10px;
}

.pagination .pagination-left-btn .pagination-right-btn {
  margin: 0 10px;
  width: 50px;
  height: 30px;
}

.sidebarScrollable {
  max-height: 920px;
  overflow-y: auto;
}

.header-right-articles {
  position: absolute;
  top: 10px;
  right: 85px;
  display: flex;
  align-items: center;
  padding-top: 50px;
}

@media (max-width: 1200px) {

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 140px;
    padding-left: 100px;
  }
  
}

@media (max-width: 480px) {
  /* .header-container-articles {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 10px;
  }

  .header-title-articles {
    font-size: 24px;
    margin-left: 0;
  }

  .filter-btn {
    width: 100%;
    height: auto;
    font-size: 20px;
    margin-bottom: 20px;
  } */

  .filter-dialog {
    width: 18%;
    left: 0;
    top: 100px; /* Adjust position as needed */
    padding: 4px;
  }

  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 0%;
    padding: 0;
    margin: 0;
  }

  .pagination {
    gap: 5px;
    padding: 10px;
  }

  .pagination .pagination-left-btn,
  .pagination .pagination-right-btn {
    width: 40px;
    height: 25px;
    font-size: 14px;
  }

  .sidebar-home, .sidebarItem-home, .sidebarList-home, .sidebarSocial-home {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .sidebarItem-home > img, .sidebarItem-home > p {
    width: 100%;
    padding: 10px;
  }

  .sidebarListItem-home {
    width: 100%;
    margin: 5px 0;
  }

  .filter-dialog h2 {
    font-size: 10px;
  }

  .checkbox-group {
    font-size: 14px;
  }

  .post {
    padding: 15px;                /* Reduces the internal spacing */
    /* margin: 8px 0;                Slightly reduces vertical spacing between posts */
    border: 1px solid #ddd;
    border-radius: 10px;           /* Slightly reduces the border radius */
    background-color: #f9f9f9;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Adjusts shadow for the smaller size */
    width: 39vh;                   /* Decreases the width of each post */
    /* max-width: 600px;             Limits the maximum width to control size */
  }

  .posts{
    padding-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-right: 0px;
    margin-left: 390px;
    gap: 0px;
    align-content: center;
    align-items: center;
  }
  
  .container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;          /* Centers the posts within the container */
  }

  .postImg{
    width: 100%;
    height: 114px;
    object-fit: cover;
    border-radius: 10px;
  }

  .postTitle{
    font-size: 14px;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    color: #272635;
    text-align: center;
  }

  .authorName-Post {
    font-size: 8px;
    font-weight: 400;
    margin-right: 30px;
    color: #272635;
}

.postDate {
  font-size: 8px;
  font-weight: 400;
  color: #272635;
}

.authorImg {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}
.post p {
  font-size: 10px;
  font-weight: 400;
  color: #272635;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.6em; /* Adjust based on line-height to fit exactly 2 lines */
  line-height: 1.3em; /* Ensure this matches your desired line spacing */
}
.readMoreButton {
  display: none;
}

.postTitleContainer {
  height: 30px;
  display: flex;
  align-items: center;
}

.header-container-articles {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes space between the items */
  padding: 0px 20px;
  margin-top: 30px;
}

.header-title-articles {
  font-size: 18px; /* Adjust as needed */
  font-weight: bold;
  color: #272635;
  margin-left: 15%; /* Removes default margin */
}

.filter-btn {
  width: 75px;
  height: 20px;
  background-color: #272635; /* Customize as needed */
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  font-size: 10px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkmark {
  width: 10px;
  height: 10px;
  background-color: #C9CDD3;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.custom-checkbox input:checked + .checkmark::after {
  content: "✔";
  color: #272635;
  position: absolute;
  top: 0;
  left: 1px;
  font-size: 8px;
}

}