.header {
  margin-top: 60px;
}

.headerTitles {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Lora", serif;
  color: #444;
}

.headerTitleSm {
  position: absolute;
  top: 18%;
  font-size: 20px;
}

.headerTitleLg {
  position: absolute;
  top: 20%;
  font-size: 100px;
}

.headerImg {
  width: 100%;
  height: 450px;
  margin-top: 80px;
  object-fit: cover;
}

/* ---------------------------------------- */

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.search-bar-container {
  width: 100%;
  height: 185px;
  background: #c9cdd3;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.input-container-search {
  position: relative;
}

.searchForm1-search > button {
  height: 52px;
  width: 52px;
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 84px;
  cursor: pointer;
}

.searchForm1-search > input:focus {
  outline: none;
}

.close-search {
  position: absolute;
  left: 70%; /* Adjusted to be right beside the input container */
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #272635;
}

/* header.css */

.blur {
  filter: blur(5px);
  pointer-events: none;
}

.menu__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999; /* Ensure it's below the menu but above the main content */
  display: none;
}

#menu__toggle:checked ~ .menu__overlay {
  display: block;
}

#menu__toggle:checked ~ .main-content {
  filter: blur(5px);
  pointer-events: none;
}

.menu__box {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: white;
  z-index: 1000; /* Ensure it's above the overlay */
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.close__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.header-sm {
  display: none; /* Hide by default */
}

@media only screen and (max-width: 480px) {

  .header-smaller-screen {
    display: flex;
    align-items: center; /* Vertically align items */
    justify-content: space-between; /* Space between elements */
    padding: 10px 20px; /* Adjust padding for spacing */
    background-color: #f9f9f9; /* Optional: Adjust background color */
    border-bottom: 1px solid #ddd; /* Optional: Add a divider */
    width: 100%; /* Ensure it spans the full width */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  
  /* Style for hamburger icon */
  .hamburger-icon {
    flex-shrink: 0; /* Prevent shrinking */
    margin-right: 10px; /* Add space from the title */
    display: flex;
    align-items: center; /* Ensure icon stays vertically centered */
  }
  
  /* Style for the header title */
  .header-title {
    flex-grow: 1; /* Allow title to take up available space */
    text-align: center; /* Center align the text */
    font-size: 16px; /* Adjust font size as needed */
    font-weight: bold; /* Make it more prominent */
    margin: 0; /* Remove default margin */
    white-space: nowrap; /* Prevent wrapping */
  }
  
  /* Style for the search icon */
  .search-icon {
    flex-shrink: 0; /* Prevent shrinking */
    margin-left: 10px; /* Add space from the title */
    display: flex;
    align-items: center; /* Ensure icon stays vertically centered */
    cursor: pointer; /* Make it interactive */
  }
  
  /* Media query for mobile (width <= 480px) */
  .sign-in{
    display: none;
  }

  .header-right-Home{
    display: none;
  }
  
  .header-sm {
    display: flex; /* Show on smaller screens */
    align-items: center; /* Vertically align items */
    justify-content: space-between; /* Space between elements */
    padding: 10px 20px; /* Add spacing */
    background-color: #f9f9f9; /* Optional: Adjust background color */
    width: 100%; /* Ensure it spans the full width */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .header-sm .hamburger-icon,
  .header-sm .search-icon {
    flex-shrink: 0; /* Prevent shrinking */
    cursor: pointer; /* Make it interactive */
  }

  .header-sm .header-title {
    flex-grow: 1; /* Allow the title to occupy available space */
    text-align: center; /* Center align the title */
    font-size: 16px; /* Adjust font size */
    font-weight: bold; /* Emphasize the title */
    margin: 0; /* Remove default margin */
    white-space: nowrap; /* Prevent wrapping */
  }
}

