/* Basic CSS resets and styles for the menu */
.hamburger-icon #menu__toggle {
  opacity: 0;
  position: absolute;
}

.menu__overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.header-container {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  background-color: #f7f8fb;
}

.header-right-Home {
  display: flex;
  align-items: center;
}

.subscribe-btn-Home {
  margin-right: 10px; /* Adjust margin as needed */
}

.separator-home {
  width: 1px;
  height: 65px; /* Adjust height as needed */
  background-color: #ccc;
  margin: 0 10px;
}

.search-icon {
  margin-right: 10px; /* Adjust margin as needed */
  cursor: pointer;
  height: 40px;
  width: 39px;
}

.hamburger-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  top: 60px;
  right: 10px;
}

.menu__btn {
  margin-right: 10px; /* Adjust margin as needed */
}

.menu__box {
  position: fixed;
  height: 100%; /* Adjust height to fit screen */
  right: -100%; /* Off-screen initially */
  width: 100%; /* Adjust width to fit screen */
  max-width: 507px; /* Maximum width */
  top: 0;
  padding: 80px 0;
  list-style: none;
  background-color: #b1e5f2;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
  transition: right 0.3s ease-in-out;
  z-index: 1001;
  overflow-y: auto; /* Allow scrolling */
  padding-top: 0px;
}

.menu__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px; /* Adjust height as needed */
  color: #272635;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.hamburger-margin{
  margin-top: 150px;
}

.menu__item a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline */
  width: 100%; /* Ensure full clickable area */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu__item:hover {
  background-color: rgba(39, 38, 53, 0.7); /* #272635 with 70% opacity */
  color: #fff; /* Change text color on hover */
  text-decoration: none;
}

.close__btn {
  position: fixed;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 30px;
  color: #272635;
  display: none;
  z-index: 1002;
}

#menu__toggle:checked ~ .menu__box ~ .close__btn {
  display: block;
}

#menu__toggle:checked ~ .menu__overlay {
  display: block;
}

#menu__toggle:checked ~ .menu__box {
  right: 0;
}

.social-media-icons {
  display: flex;
  justify-content: space-evenly;
  padding: 10px 0;
}
.header-title{
  display: none;
}

@media (min-width: 768px) and (max-width: 991.98px) and (min-height: 576px) and (max-height: 767.98px) {
  .menu__box {
    position: fixed;
    height: 100%; /* Adjust height to fit screen */
    right: -100%; /* Off-screen initially */
    width: 100%; /* Adjust width to fit screen */
    max-width: 450px; /* Maximum width */
    top: 0;
    list-style: none;
    background-color: #b1e5f2;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transition: right 0.3s ease-in-out;
    z-index: 1001;
    overflow-y: auto; /* Allow scrolling */
    margin-right: 60px;
  }

  .menu__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px; /* Adjust height as needed */
    color: #272635;
    font-size: 25px;
    font-weight: 700;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin-top: 15px;
  }

  .hamburger-margin{
    margin-top: 0px;
  }

  .close__btn {
    position: fixed;
    top: 20px;
    right: 85px;
    cursor: pointer;
    font-size: 30px;
    color: #272635;
    display: none;
    z-index: 1002;
  }
}

@media only screen and (max-width: 480px) {
  /* Styles for small mobile devices */
  
  .header-container {
    display: flex;
    justify-content: space-between; /* Space out the items */
    align-items: center; /* Center items vertically */
    padding: 10px; /* Add some padding */
  }

  .hamburger-icon {
    order: 1; /* Move hamburger to the left */
    flex: 0 0 auto; /* Prevent it from growing */
  }

  .header-title {
    order: 2; /* Center the title */
    flex-grow: 1; /* Allow title to occupy the center space */
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .header-right-Home {
    display: none;
  }

  .header-title {
    display: block;
    order: 2; /* Center the title */
    flex: 1; /* Allow title to take up available space */
    text-align: center; /* Center text */
    font-size: 20px; /* Adjust font size */
    font-weight: bold; /* Make title bold */
  }

  /* Additional styles for search icon */
  .search-icon {
    order: 3; /* Place the search icon on the right */
    margin-right: 10px; /* Adjust margin for spacing */
    cursor: pointer;
  }

  /* Remove unnecessary styles */
  .separator-home {
    display: none; /* Hide separator on mobile */
  }

  .menu__box {
    width: 100%;
    max-width: 100%; /* Adjust for small screens */
    padding: 50px 0;
    
  }

  .menu__item {
    font-size: 20px;
    margin-top: 10px;
    height: 60px;
  }

  .hamburger-margin {
    margin-top: 50px;
  }

  .close__btn {
    font-size: 25px;
    top: 2%;
    right: 6%;
  }

  .search-bar-container input {
    width: 100%;
    height: 40px;
  }

  .searchSubmit1 {
    right: 5px;
    top: 5px;
  }

  .subscribe-btn-Home {
   display: none;
  }

  .logoHome img{
    display: none;
  }
}