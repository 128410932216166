
.form-section {
  margin-top: 20px;
  padding: 20px;
  /* background-color: #272635; */
  color: #272635;
}

/* Cards CSS for author preferences */

.card-container-author {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to new lines */
  margin-top: 20px;
  margin-bottom: 30px;
  justify-content: space-between;

}

.card {
  width: 150px; /* Set card width */
  height: 150px; /* Set card height */
  margin: 30px; /* Adjust margin between cards */
  perspective: 1000px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  cursor:pointer;
  object-fit: contain;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-size: cover; /* Ensure image covers the entire card */
  background-repeat: no-repeat; /* Prevent image from repeating */
}

.card-front {
  border-radius: 10px;
 
}

.card-back {
  background: white;
  transform: rotateY(180deg);
  text-align: center;
  border-radius: 10px;
}

.card:nth-child(n) {
  margin-bottom: 25px; /* Adjust the margin-top value as needed */
}

.card-wrapper {
  display: inline-block;
  text-align: center;
}
.author-name {
  margin-top: 10px; /* Adjust margin as needed */
}

.card.selected {
  border: 5px solid #b1e5f2;
  display: flex;
  flex-wrap: wrap;
}

.heading-class-author {
  color: #272635;
  /* font-weight: 400; */
  font-size: 20px;
  margin-left: 20px;
}

/* --------------Save Button-------------------- */

.button {
  padding: 10px 20px;
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
}

.button:hover {
  background-color: #90cad7;
}

.save-button {
  padding: 10px 35px; /* Adjust padding for smaller size */
  background-color: #272635;
  color: #b1e5f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #90cad7;
}