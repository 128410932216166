@font-face {
  font-family: "Roboto";
  src: url("../../../../public/web\ fonts/roboto_black_macroman/Roboto-Black-webfont.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.heading-class-genre {
  color: #272635;
  font-weight: 400;
  font-size: 24px;
  margin-left: 40px;
  font-family: "Roboto";
}
.options {
  display: flex;
  flex-wrap: wrap; /* Wrap the options to new line */
  margin-top: 40px;
  margin-left: 30px;
}

.option {
  width: 150px;
  height: 30px;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-basis: calc(50% - 20px); /* Two columns with margin */
  cursor: pointer;
  margin-bottom: 90px;
  font-family: "Roboto";
}

.option input[type="checkbox"] {
  width: 49px;
  height: 49px;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: none;
  border-radius: 5px;
  outline: none;
  appearance: none; /* Removes default checkbox style */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: #c9cdd3; /* Default grey background */
  border: 1px solid #c9cdd3; /* Border matching background color */
  position: relative;
}

.option input[type="checkbox"]::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 20px;
  border: solid #272635;
  border-width: 0 3px 3px 0;
  transform: translate(-50%, -66%) rotate(45deg) scale(0);
  transition: transform 0.2s ease-in-out;
}

.option input[type="checkbox"]:checked::after {
  transform: translate(-50%, -50%) rotate(45deg) scale(1);
}

.option input[type="checkbox"]:checked {
  background-color: #c9cdd3; /* Maintain the same grey background */
  border: 1px solid #c9cdd3; /* Border matching background color */
}

.option label {
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* ---------------------------------------------- */

.otherInputContainer {
  display: flex; /* Use flexbox to align items horizontally */
  align-items: center; /* Align items vertically */
  margin-top: 5px; /* Adjust margin to align with other options */
  display: flexbox; /* Initially hide the input container */
  width: 175px;
}

#otherInput {
  width: calc(100% - 10px); /* Make input field fill the container width */
  padding: 6px; /* Add some padding to the input field */
  border: 2px solid #ccc; /* Add a border to the input field */
  border-radius: 5px; /* Add rounded corners to the input field */
  margin: 10px;
}

/* --------------Save Button-------------------- */

.button {
  padding: 10px 20px;
  color: #b1e5f2;
  background-color: #272635;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 50%;
}

.button:hover {
  background-color: #90cad7;
}

.save-button {
  padding: 10px 35px; /* Adjust padding for smaller size */
  background-color: #272635;
  color: #b1e5f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #90cad7;
}
