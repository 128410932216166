 /* ------------------------------------ */
    .profile-pic {
      color: transparent;
      transition: all 0.3s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.3s ease;
    }
    .profile-pic input {
      display: none;
    }
    .profile-pic img {
      position: absolute;
      object-fit: cover;
      width: 165px;
      height: 165px;
      box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
      border-radius: 100px;
      z-index: 0;
    }
    .profile-pic .-label {
      cursor: pointer;
      height: 165px;
      width: 165px;
    }
    .profile-pic:hover .-label {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 10000;
      color: #fafafa;
      transition: background-color 0.2s ease-in-out;
      border-radius: 100px;
      margin-bottom: 0;
    }
    .profile-pic span {
      display: inline-flex;
      padding: 0.2em;
      height: 2em;
    }
    body a:hover {
      text-decoration: none;
    }

    /* ------------------------------------ */
    body {
      background-color: #F7F8FB; /* Set background color for the entire page */
    }

    .container-profile {
      margin-top: 20px; /* Add margin to separate header from cards */
    }

    .header-profile {
      background-color: #F7F8FB;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }

    .logo-profile img {
      mix-blend-mode: multiply;
    }

    .cards-profile {
      position: relative;
      display: flex;
      justify-content: space-evenly;
      margin-top: 20px; /* Add margin to separate cards */
    }

    .left-card-profile,
    .right-card-profile {
      border-radius: 5%; /* Apply 5% border radius to both cards */
    }

    .left-card-profile {
      width: 449px;
      height: 535px;
      background-color: #b1e5f2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 60px;
    }

    .user-info-profile {
      text-align: center;
    }

    .profile-pic {
      width: 150px;
      height: 150px;
      background-color: #ccc; /* Placeholder color */
      border-radius: 50%; /* Circular shape */
      margin: 20px auto; /* Center the profile picture */
      overflow: hidden; /* Hide overflow from circular shape */
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; /* Center the icon vertically */
    }
    .input-container {
      position: relative;
    }

    #editableInput {
      width: 358px; /* Set width to 358px */
      height: 176px; /* Set height to 176px */
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 10px;
      resize: none; /* Prevent resizing */
      overflow-y: auto; /* Enable vertical scrollbar when needed */
      word-wrap: break-word; /* Allow text to wrap to a new line */
      background-color:  #E8E9F3;
      font-size: 16px;
      font-weight: 700;
      box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.25);;
    }

    .pencil-icon {
      position: absolute;
      bottom: 6px;
      right: 6px;
      cursor: pointer;
    }

    .right-card-profile {
      width: 827px; /* Adjust width to fill remaining space */
      min-height: auto;
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      /* background-color: #E8E9F3; */
      background: linear-gradient(to top, #e8e9f3 90%, #272635 10%);
      border-radius: 10px;
      margin-right: 60px;
      margin-bottom: 36px;
    }

    /* Profile Pic */

    .edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, -25%); /* Adjusted positioning for icon */
      background-color: #ffffff88; /* Semi-transparent background */
      border-radius: 50%;
      cursor: pointer;
      padding: 5px;
    }

    .edit-icon svg {
      fill: #333;
      width: 24px;
      height: 24px;
    }

    #profile-preview {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensure image covers the entire circular area */
    }

    .edit-profile {
      font-size: 24px;
      margin-bottom: 10px;
      color: white;
      margin-left: 45px;
      margin-top: 10px;
    }

    /* Added for accessibility */
    .visually-hidden {
      position: absolute !important;
      clip: rect(0 0 0 0) !important;
      height: 1px !important;
      width: 1px !important;
      overflow: hidden !important;
      border: 0 !important;
      padding: 0 !important;
      margin: -1px !important;
    }
    .divider {
      border-color: white;
      margin-bottom: 20px;
    }

    .profile-sections {
      list-style-type: none;
      padding-bottom: 30px;
      padding-top: 10px;
      display: flex;
      color: white;
      font-size: 20px;
      gap: 24px;
    }

    /*  Form for User Info */

    /* .form-section-profile2 {
      margin-top: 20px;
      padding: 10px;
      
      color: #272635;
    } */

    .button {
      padding: 10px 20px;
      color: #b1e5f2;
      background-color: #272635;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      width: 50%;
    }

    .button:hover {
      background-color: #90cad7;
    }

    .save-button {
      padding: 10px 35px; /* Adjust padding for smaller size */
      background-color: #272635;
      color: #b1e5f2;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .save-button:hover {
      background-color: #90cad7;
    }

    /* Side by Side form input */

    /* Asterick ** */

    #user-info-form {
      display: none; /* Hide the form initially */
    }

    .profile-sections li a {
      text-decoration: none; /* Remove underline */
      color: inherit; /* Inherit text color */
    }

    .profile-sections li a:hover {
      text-decoration: none; /* Remove underline on hover */
      color: inherit; /* Inherit text color on hover */
    }

    /* Cards CSS for author preferences */

    /* Genre Preferences */

    /* ---------------------------------------------- */

    .profile-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      text-decoration: none;
      color: #e8e9f3; /* Default button color */
      transition: color 0.3s ease; /* Smooth transition for color change */
    }

    .profile-button:focus,
    .profile-button:hover,
    .profile-button.active {
      color: #b1e5f2; /* Change button color on hover/focus/when active */
    }
    /* --------------------------------------- */

    .hamburger-icon {
      font-size: 20px;
      cursor: pointer;
      margin-right: 20px;
    }

    /* Mobile menu styles */
    .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: #b1e5f2;
      justify-content: center;
      align-items: center;
      z-index: 99;
      transition: opacity 0.3s ease-in-out;
      padding-top: 300px;
    }

    .mobile-menu.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .mobile-menu-content {
      border-radius: 10px;
      text-align: center;
    }

    .menu-items {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 35px; /* Adjust font size as needed */
    }

    /* Background on menu item, clear bottom border (optional) */
    .menu-item {
      margin-bottom: 1rem;
      position: relative; /* Enable positioning of child element */
      overflow: hidden; /* Hide overflowing content */
      background-color: transparent; /* Set background for menu item */
      border-bottom: none; /* Clear potential default border (optional) */
    }

    .menu-item a {
      color: #272635;
      text-decoration: none;
      font-size: inherit; /* Inherit font size from .menu-items */
      transition: color 0.3s ease-in-out;
      width: 100%; /* Stretch link to full width */
      display: block; /* Make link take full height of menu item */
      padding: 1rem 0; /* Add some padding for visual separation */
      position: relative; /* Position for layering */
    }

    .menu-item a:hover {
      color: #f7f8fb; /* Hover text color */
      background-color: #272635;
    }

    /* Animated hover effect remains the same */
    .menu-item a:hover::after {
      content: "";
      position: absolute;
      top: 0;
      left: -100%; /* Start from left off-screen */
      width: 100%;
      height: 100%;
      background-color: #272635; /* Hover background color */
      transition: left 0.3s ease-in-out;
    }

    /* Close icon styles */
    .close-icon-container {
      position: absolute;
      top: 5rem;
      right: 2rem;
      cursor: pointer;
    }

    /* -------------------- */

    .social-icons {
      position: absolute;
      bottom: 1rem; /* Adjust distance from bottom */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%); /* Center horizontally (alternative) */
      display: flex; /* Arrange icons horizontally */
      justify-content: center; /* Center icons horizontally */
      /* Add spacing between icons */
      gap: 50px; /* Adjust spacing between icons as needed */
    }

    .form-section-profile2 {
      /* Other styles */
      height: auto; /* Allow the height to adjust based on content */
      padding: 20px; /* Optional: Add padding for better spacing */
      transition: height 0.3s ease; /* Smooth transition if height changes */
    }
